import React from "react";
import { industryCoaches } from "../utils/constants";
import "./sections/industry_coaches.scss";

const Marquee =
  typeof window !== `undefined`
    ? require("react-simple-marquee").default
    : null;

export const IndustryCoaches = () => (
  <div id="industry-coaches-section" className="industry-coaches-section">
    <h2 id="trending-section-heading">Our coaches are industry experts from</h2>
    <div id="industry-coaches-container">
      {typeof window !== "undefined" && (
        <Marquee>
          {industryCoaches.map((coach, index) => (
            <img
              key={index}
              src={coach.logoUrl}
              className="industry-coach-company-logo"
              alt={coach.name}
            />
          ))}
        </Marquee>
      )}
    </div>
  </div>
);

export default IndustryCoaches;
