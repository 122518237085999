import PropTypes from "prop-types";
import React from "react";

export const CTACard = ({
  heading,
  id,
  subheading,
  buttonText,
  desktopImage,
  mobileImage,
  imageOnLeft,
  useColumns,
  buttonLink,
  endingRawHtml,
}) => (
  <div className={"cta-card-container"} id={id}>
    {imageOnLeft && (
      <img
        className="cta-card-image cta-card-image-left mobile-hidden"
        src={desktopImage}
        alt="cta-card-image"
      />
    )}
    <div
      className={`cta-card-text-container ${
        imageOnLeft ? "cta-card-text-left-padding" : ""
      } ${useColumns ? "cta-card-text-columns" : ""}`}
    >
      <h2 className="cta-card-heading">{heading}</h2>
      <h6 className="cta-card-subheading">{subheading}</h6>
      {endingRawHtml && (
        <div dangerouslySetInnerHTML={{ __html: endingRawHtml }} />
      )}
      {buttonText && (
        <button
          className={"cta-card-button"}
          onClick={() => window.open(buttonLink, "_blank")}
        >
          {buttonText}
        </button>
      )}
    </div>
    {/*<div className={"cta-card-image-container"}>*/}
    {!imageOnLeft && (
      <img
        alt="cta-card-image"
        className="cta-card-image mobile-hidden"
        src={desktopImage}
      />
    )}
    {/*</div>*/}
    <div className={"cta-card-mobile-image-container"}>
      <img
        className={"cta-card-mobile-image desktop-hidden"}
        src={mobileImage}
        alt="cta-card-image"
      />
    </div>
  </div>
);

CTACard.propTypes = {
  id: PropTypes.string,
  image: PropTypes.object,
  heading: PropTypes.node,
  subheading: PropTypes.node,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  desktopImage: PropTypes.node,
  mobileImage: PropTypes.node,
  imageOnLeft: PropTypes.bool,
  useColumns: PropTypes.bool,
};

export default CTACard;
