import React from "react";
import CTAButton from "./CTAButton";
import QuoteIcon from "../img/images/icons/noun_Quote_3267252 (1).png";
import StarRatings from "./StarRatings";
import { Swiper, SwiperSlide } from "swiper/react";
import PropTypes from "prop-types";
import { testimonials } from "../utils/constants";
import Img from "gatsby-image";
import { BECOME_A_COACH_FORM_URL } from "../utils/localization";
import "../components/sections/about.scss";

let TestimonialSection;
export default TestimonialSection = () => {
  let swiper = null;

  return (
    <div id={"about-page-testimonials-section"}>
      <div className="row">
        <div className="col-md-4 about-page-hear-from-our-unschoolers">
          <h2>Hear from our Coaches</h2>
          <p>Testimonials from our coaches at Unschool</p>
          <CTAButton
            text={"COACH AT UNSCHOOL"}
            link={BECOME_A_COACH_FORM_URL}
          />
        </div>
        <div className="col-md-8" id={"testimonial-container"}>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            onSwiper={(s) => (swiper = s)}
            loop={true}
          >
            {testimonials.map((testimonial) => (
              <SwiperSlide>
                <div className={"about-testimonials-card"}>
                  <div className="about-testimonials-card-text">
                    <img
                      src={QuoteIcon}
                      alt={"Quote Icon"}
                      className={"about-page-testimonial-quote-image"}
                    />
                    <p>{testimonial.testimonial}</p>
                    <div className={"about-testimonials-card-rating-container"}>
                      <StarRatings
                        rating={testimonial.rating}
                        reviewers={testimonial.ratingsCount}
                        spacing={4}
                      />
                    </div>
                    <div className={"about-testimonials-card-person"}>
                      <h4>{testimonial.name}</h4>
                      <h5>Teaches {testimonial.credential}</h5>
                    </div>
                  </div>
                  <div className="about-testimonials-card-image">
                    <Img
                      fluid={{ src: testimonial.imgUrl }}
                      objectFit="cover"
                      imgStyle={{ objectFit: "cover" }}
                      style={{ height: "100%" }}
                      alt={"Testimonial Image"}
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className={"about-testimonials-card-stepper"}>
            <span onClick={() => swiper.slidePrev()}>←</span>
            <span onClick={() => swiper.slideNext()}>→</span>
          </div>
        </div>
      </div>
    </div>
  );
};

TestimonialSection.propTypes = {
  testimonials: PropTypes.array,
};
