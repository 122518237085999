import React from "react";
import Layout from "../../components/Layout";
import HeroImage from "../../img/images/cards/group-1157.png";
import CTAButton from "../../components/CTAButton";
import StructureIcon from "../../img/images/icons/become-a-coach/noun_Flow Chart_19378.png";
import HelpIcon from "../../img/images/icons/become-a-coach/noun_teamwork_219695.png";
import CommunityIcon from "../../img/images/icons/become-a-coach/noun_Community_2977056.png";
import ListIcon from "../../img/images/icons/become-a-coach/noun_List_1015747.png";
import CTACard from "../../components/CTACard";
import LifelongAccessImage from "../../img/images/cards/Unschool Lifelong Access Card.png";
import LifelongAccessMobileImage from "../../img/images/cards/coaching/group-1596@2x.png";
import StatsSection from "../../components/StatsSection";
import PillHeader from "../../components/PillHeader";
import SetupImage from "../../img/images/cards/coaching/linkedin-sales-navigator-sWxKwsgY57c-unsplash.jpg";
import FinanceImage from "../../img/images/cards/coaching/glenn-carstens-peters-RLw-UC03Gwc-unsplash (1).jpg";
import GrowImage from "../../img/images/cards/coaching/austin-distel-wD1LRb9OeEo-unsplash.jpg";
import Step1Image from "../../img/images/cards/coaching/steps/Group 2203.png";
import Step2Image from "../../img/images/cards/coaching/steps/Group 2204.png";
import Step3Image from "../../img/images/cards/coaching/steps/Group 2205.png";
import YouAreTheInnovationImage from "../../img/images/cards/coaching/Rectangle 316.png";
import TechStreamImage from "../../../static/img/streams/Rectangle 254.png";
import ScienceStreamImage from "../../../static/img/streams/Rectangle 256.png";
import EntrepreneurshipStreamImage from "../../../static/img/streams/Rectangle 255.png";
import CreativeStreamImage from "../../../static/img/streams/Rectangle 257.png";
import BusinessStreamImage from "../../../static/img/streams/Rectangle 258.png";
import TestimonialSection from "../../components/TestimonialSection";
import IndustryCoaches from "../../components/IndustryCoaches";
import FAQSection from "../../components/FAQSection";
import NewsletterCard from "../../components/NewsletterCard";
import MobileHeroImage from "../../img/images/cards/group-1159.png";
import { graphql, StaticQuery } from "gatsby";
import "../../components/sections/coach.scss";
import { faqs } from "../../utils/constants";
import { BECOME_A_COACH_FORM_URL } from "../../utils/localization";

const BecomeACoachPage = ({ data }) => {
  // const { data } = this.props;
  // const { testimonials, industry_coaches, Faqs } = data;
  const pageTitle = "Become a Coach | Unschool";
  const pageDescription =
    "Are you an expert in your field? Become a part of the ever-growing Unschool Coach community and get paid for your knowledge.";
  return (
    <Layout title={pageTitle} description={pageDescription}>
      <section id={"become-a-coach-page"}>
        <div id={"become-a-coach-hero"}>
          <div className={"row"}>
            <div className="col-md-6" id={"become-a-coach-hero-left"}>
              <h1>
                Be the{" "}
                <span className={"become-a-coach-hero-gradient-text"}>
                  trump card.
                </span>
                <br />
                Let{" "}
                <span className={"become-a-coach-hero-gradient-text"}>
                  Education win.
                </span>
              </h1>
              <h4>
                You have the knowledge, we have the platform. Let’s help people
                Upskill, shall we?
              </h4>
              <CTAButton
                text={"Become a coach"}
                link={BECOME_A_COACH_FORM_URL}
              />
              <div id={"become-a-coach-image-mobile-container"}>
                <img
                  id={"become-a-coach-image-mobile"}
                  src={MobileHeroImage}
                  className={"desktop-hidden"}
                  alt="become-a-coach"
                />
              </div>
            </div>
            <div className="col-md-6">
              <img
                id={"become-a-coach-image"}
                className={"mobile-hidden"}
                src={HeroImage}
                alt={"Become a coach hero image"}
              />
            </div>
          </div>
        </div>

        <div id={"become-a-coach-sub-hero-cta"}>
          <h1 className={"mobile-hidden"}>
            A community of 200+ Coaches, <br className={"desktop-hidden"} />{" "}
            Capable of <br className={"mobile-hidden"} />
            <span className={"yellow-underline-text"}>
              impacting 1.3 billion more.
            </span>
          </h1>
          <h1 className={"desktop-hidden"}>
            A community of 200+ Coaches, <br className={"desktop-hidden"} />{" "}
            Capable of impacting 1.3 billion more.
          </h1>
          <div className={"yellow-underline-mobile-small desktop-hidden"} />
          <p>
            We truly believe in transforming the Indian education system for the
            better. In igniting imagination, breaking through inhibitions and
            helping learners replace doubt with fearlessness. Join us!
          </p>
        </div>

        <div id={"become-a-coach-all-you-need-to-know"}>
          <div className="row">
            <div className="col-md-4">
              <h2>All you need to know to get started</h2>
              <CTAButton
                text={"Become a coach"}
                link={BECOME_A_COACH_FORM_URL}
                className={"mobile-hidden"}
              />
            </div>
            <div className="col-md-8" id={"become-a-coach-things-to-know"}>
              <div className="become-a-coach-thing-to-know-item">
                <img src={ListIcon} alt="List Icon" />
                <div>
                  <h4>Plan your course</h4>
                  <p>
                    Every coach has a unique teaching style, and we let you
                    explore yours! Plan your course the way you’d love to
                    deliver it.
                  </p>
                </div>
              </div>
              <div className="become-a-coach-thing-to-know-item">
                <img
                  src={StructureIcon}
                  alt="Structure Icon"
                  className={"b-a-c-icon-orange"}
                />
                <div>
                  <h4>Create your curriculum</h4>
                  <p>
                    The first step to better education is no ‘set syllabus’. You
                    teach your learners what you deem fit, through your
                    experience.
                  </p>
                </div>
              </div>
              <div className="become-a-coach-thing-to-know-item">
                <img
                  src={CommunityIcon}
                  alt="Community Icon"
                  className={"b-a-c-icon-purple"}
                />
                <div>
                  <h4>Build your community</h4>
                  <p>
                    Enable peer-to-peer learning and nurture a community of
                    enthusiastic learners.
                  </p>
                </div>
              </div>
              <div className="become-a-coach-thing-to-know-item">
                <img
                  src={HelpIcon}
                  alt="Help Icon"
                  className={"b-a-c-icon-green"}
                />
                <div>
                  <h4>Junior coach support</h4>
                  <p>
                    Lead with thought, let your junior coach execute and take
                    care of everything!
                  </p>
                </div>
              </div>

              <CTAButton
                text={"Become a coach"}
                link={BECOME_A_COACH_FORM_URL}
                className={"desktop-hidden"}
              />
            </div>
          </div>
        </div>

        <CTACard
          id={"become-a-coach-cta-card"}
          heading={"Calling all junior experts!"}
          subheading={
            "Work under a senior, experienced coach and learn about the industry. This program is specifically designed for college graduates and freshers, to add a chip to their resume and start coaching in this paid internship."
          }
          buttonText={"Apply now"}
          desktopImage={LifelongAccessImage}
          mobileImage={LifelongAccessMobileImage}
          buttonLink={BECOME_A_COACH_FORM_URL}
        />

        <StatsSection id={"coach-page-stats"} />

        <div id={"things-about-coaching-section"}>
          <PillHeader text={"Things about coaching"} />
          <div className="row things-about-coaching-items">
            <div className="col-md-4 things-about-coaching-item">
              <img src={SetupImage} alt="setup-image" />
              <h4>Setup</h4>
              <p>More on how to set up your classroom on Unschool</p>
              {/*<a href={"#"}>Learn More</a>*/}
            </div>
            <div className="col-md-4 things-about-coaching-item">
              <img src={FinanceImage} alt="finance-img" />
              <h4>Finance</h4>
              <p>How you grow monetarily with us</p>
              {/*<a href={"#"}>Learn More</a>*/}
            </div>
            <div className="col-md-4 things-about-coaching-item">
              <img src={GrowImage} alt="grow-image" />
              <h4>Grow with other coaches</h4>
              <p>How our coaching community learns and supports each other</p>
              {/*<a href={"#"}>Learn More</a>*/}
            </div>
          </div>
        </div>

        <div id={"how-to-become-a-coach-section"}>
          <h4 className={"mobile-hidden"}>How to become a coach</h4>
          <PillHeader
            text={"How to become a coach"}
            id={"how-to-become-coach-heading-mobile"}
          />

          <div className="row" id={"become-a-coach-steps-container"}>
            <div className="col-md-4 become-a-coach-step">
              <div className={"become-a-coach-step-no mobile-hidden"}>1</div>
              <img src={Step1Image} alt="step-image" />
              <div>
                <div className={"become-a-coach-step-no desktop-hidden"}>1</div>
                <h4>We receive your application</h4>
                <p>
                  Send in your details and apply to be a coach through a simple
                  application
                </p>
              </div>
            </div>
            <div className="col-md-4 become-a-coach-step">
              <div className={"become-a-coach-step-no mobile-hidden"}>2</div>
              <img src={Step2Image} alt="step-image" />
              <div>
                <div className={"become-a-coach-step-no desktop-hidden"}>2</div>
                <h4>Screening and verification</h4>
                <p>
                  We have a thorough screening process to ensure quality of
                  education
                </p>
              </div>
            </div>
            <div className="col-md-4 become-a-coach-step">
              <div className={"become-a-coach-step-no mobile-hidden"}>3</div>
              <img src={Step3Image} alt="step-image" />
              <div>
                <div className={"become-a-coach-step-no desktop-hidden"}>3</div>
                <h4>On-boarding as a coach</h4>
                <p>
                  A seamless process to transition you into the Unschool family
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id={"you-are-the-innovation"}>
          <div id={"yati-img"}>
            <img src={YouAreTheInnovationImage} alt="innovation-img" />
          </div>
          <div id={"yati-text"}>
            <h2>You are the innovation that education needs.</h2>
            <p>
              And we’re here to support you through and through. 24/7 support
              and dedicated teams for your assistance.
            </p>
            {/*<a href="#">Learn More</a>*/}
          </div>
        </div>

        <div id="popular-coaching-streams">
          <PillHeader text={"Popular coaching streams on Unschool"} />
          <div id="popular-coaching-streams-list">
            <div className="popular-coaching-stream">
              <div className={"pcs-bg-img"}>
                <img src={TechStreamImage} alt="pcs-img" />
              </div>
              <div className="pcs-text">
                <h5>Technology</h5>
                <p>
                  Shape the future of technology with courses in AI, Machine
                  Learning and more
                </p>
              </div>
            </div>
            <div className="popular-coaching-stream">
              <div className={"pcs-bg-img"}>
                <img src={EntrepreneurshipStreamImage} alt="pcs-img" />
              </div>
              <div className="pcs-text">
                <h5>Entrepreneurship</h5>
                <p>
                  Shape the future of technology with courses in AI, Machine
                  Learning and more
                </p>
              </div>
            </div>
            <div className="popular-coaching-stream">
              <div className={"pcs-bg-img"}>
                <img src={ScienceStreamImage} alt="pcs-img" />
              </div>
              <div className="pcs-text">
                <h5>Science</h5>
                <p>
                  Shape the future of technology with courses in AI, Machine
                  Learning and more
                </p>
              </div>
            </div>
            <div className="popular-coaching-stream">
              <div className={"pcs-bg-img"}>
                <img src={CreativeStreamImage} alt="pcs-img" />
              </div>
              <div className="pcs-text">
                <h5>Creative arts</h5>
                <p>
                  Shape the future of technology with courses in AI, Machine
                  Learning and more
                </p>
              </div>
            </div>
            <div className="popular-coaching-stream">
              <div className={"pcs-bg-img"}>
                <img src={BusinessStreamImage} alt="pcs-img" />
              </div>
              <div className="pcs-text">
                <h5>Business</h5>
                <p>
                  Shape the future of technology with courses in AI, Machine
                  Learning and more
                </p>
              </div>
            </div>
          </div>
        </div>

        <TestimonialSection />

        <IndustryCoaches />

        <FAQSection questions={faqs} />

        <NewsletterCard />
      </section>
    </Layout>
  );
};

export default BecomeACoachPage;

// export default () => (
//   <StaticQuery
//     query={graphql`
//       query BecomeACoachPage {
//         testimonials: markdownRemark(
//           frontmatter: { templateKey: { eq: "testimonials" } }
//         ) {
//           frontmatter {
//             testimonials {
//               name
//               image {
//                 childImageSharp {
//                   fluid(maxHeight: 800, quality: 70) {
//                     ...GatsbyImageSharpFluid_withWebp
//                   }
//                 }
//               }
//             }
//           }
//         }
//         industry_coaches: markdownRemark(
//           frontmatter: { templateKey: { eq: "industry_coaches" } }
//         ) {
//           frontmatter {
//             industry_coaches {
//               name
//               logo {
//                 childImageSharp {
//                   fixed(width: 160, quality: 70) {
//                     ...GatsbyImageSharpFixed_withWebp
//                   }
//                 }
//               }
//             }
//           }
//         }
//         Faqs: markdownRemark(frontmatter: { templateKey: { eq: "faq" } }) {
//           frontmatter {
//             questions {
//               question
//               answer
//             }
//           }
//         }
//       }
//     `}
//     render={(data) => <BecomeACoachPage data={data} />}
//   />
// );
