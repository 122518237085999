import React from "react";
import PropTypes from "prop-types";

const PillHeader = ({ id, text }) => {
  return (
    <div id={id}>
      <h2 className={"section-pill-header-desktop mobile-hidden"}>{text}</h2>
      <div className="section-header-container desktop-hidden">
        <h2 className="section-header">{text}</h2>
        <div className="section-header-underline" />
      </div>
    </div>
  );
};

PillHeader.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
};

export default PillHeader;
